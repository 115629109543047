import React from "react";
import styled from "styled-components";

import Inner from "./../components/Inner";
import Logo from "./../components/Logo";
import { media } from "../common/MediaQueries";

const IntroStyled = styled(Inner)`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const IntroContentStyled = styled.div`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 1;
  @media ${media.phone} {
    justify-content: center;
  }
`;

const IntroLogoStyled = styled.div`
  padding: 12px 0;
  margin-bottom: 60px;
  height: 68px;
  width: 100%;
  z-index: 1;

  @media ${media.phone} {
    margin-bottom: 0;
    padding: 32px 0;
    height: 110px;
    margin-bottom: 20px;
  }
`;

const Intro = ({ children }) => (
  <IntroStyled>
    <IntroLogoStyled>
      <Logo
        fontSize={["3.5rem", "3.5rem", "5rem"]}
        height={["30px", "30px", "45px"]}
        href="/"
      />
    </IntroLogoStyled>
    <IntroContentStyled>{children}</IntroContentStyled>
  </IntroStyled>
);

export default Intro;
