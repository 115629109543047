import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/Seo";
import Layout from "../components/Layout";
import Container from "../components/Container";
import ContentWrapper from "../components/ContentWrapper";
import PageNotFound from "../components/PageNotFound";
import Intro from "../components/Intro";

const NotFoundPage = ({ data }: { data: any }) => (
  <Layout 
    headerLocation=""
    isIntro
  >
    <SEO 
      title="Not found page - flatworld.co"
      url="/404/"
    />
    <ContentWrapper>
      <Intro>
        <Container zIndex={["1", "1", "auto"]}>
          <PageNotFound
            desc={
              <>
                <span>404</span> - page not found
              </>
            }
            body="The page you are looking for might have been removed had its name
                changed or is temporarily unavaiable."
            image={["", data.personImg]}
            url="/"
            urlText="go to homepage"
          />
        </Container>
      </Intro>
    </ContentWrapper>
  </Layout>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    personImg: file(relativePath: { eq: "pagenotfound-photo.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          formats: [AUTO, WEBP]
        )
      }
    }
  }
`;
