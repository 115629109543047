import React from "react";

import {
  PageNotFoundStyled,
  PageNotFoundHeadStyled,
  PageNotFoundDescStyled,
  PageNotFoundBodyStyled,
  PageNotFoundFooterStyled,
  PageNotFoundHeadMediaStyled,
  PageNotFoundHeadMediaItemStyled,
  PageNotFoundHeadMediaTextStyled,
} from "./styles";
import { Heading13, Typo13 } from "./../Typography";
import ButtonLink from "./../ButtonLink";
import Img from "./../Image";

interface IProps {
  desc?: React.ReactNode;
  body?: string;
  image?: React.ReactNode;
  urlText: string;
  url: string;
}

export const PageNotFound: React.FC<IProps> = ({
  desc,
  body,
  image,
  urlText,
  url,
}) => (
  <PageNotFoundStyled>
    <PageNotFoundHeadStyled>
      <PageNotFoundHeadMediaStyled>
        <PageNotFoundHeadMediaItemStyled>
          <Img fluid={image} />
        </PageNotFoundHeadMediaItemStyled>
        <PageNotFoundHeadMediaTextStyled>O</PageNotFoundHeadMediaTextStyled>
      </PageNotFoundHeadMediaStyled>
      ops!
    </PageNotFoundHeadStyled>
    {desc && (
      <PageNotFoundDescStyled>
        <Heading13 fontWeight="700" textAlign="center">
          {desc}
        </Heading13>
      </PageNotFoundDescStyled>
    )}
    {body && (
      <PageNotFoundBodyStyled>
        <Typo13 fontWeight="400" textAlign="center">
          {body}
        </Typo13>
      </PageNotFoundBodyStyled>
    )}
    <PageNotFoundFooterStyled>
      <ButtonLink $internal $watermelon $isBold to={url}>
        {urlText}
      </ButtonLink>
    </PageNotFoundFooterStyled>
  </PageNotFoundStyled>
);

export default PageNotFound;
